export interface NotificationRequest {
  title: string;
  message: string;
  notificationType: NotificationTypes;
  employeeId: number;
  calendarJobId: number;
}

export enum NotificationTypes {
  TEXT = "TEXT",
  GEOLOCATE = "GEOLOCATE",
}
