
































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import { CalendarJobGeo } from "@/models/entities/Calendar-job-geo.interface";
import { CalendarJobById } from "@/models/entities/calendar-job-byId.interface";
import { notification } from "@/services/api/notification.service";
import {
  CMapMarkers,
  CMarker,
  CMarkerTitles,
} from "@/models/utils/c-map-markers.interface";
import {
  NotificationRequest,
  NotificationTypes,
} from "@/models/requests/notification-request.interface";
import { CBtn } from "@/models/utils/c-btn.interface";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";
import AuthModule from "@/store/modules/auth.module";
import { Roles } from "@/models/entities/role.interface";
import { required } from "@/helpers/rules";
import { OfficeGeoRequest } from "@/models/requests/office-geo-request.interface";
const authModule = getModule(AuthModule, store);
const snackbarModule = getModule(SnackbarModule, store);
const overlayModule = getModule(OverlayModule, store);

@Component
export default class CMap extends Vue {
  @Prop({ required: false, default: null, type: Object })
  private mapData: CalendarJobGeo;
  @Prop({ required: false, default: null, type: Object })
  private job: CalendarJobById;

  private required = required;

  private MAP_MODE = "place";
  private ZOOM = 5;
  private API_KEY = process.env.VUE_APP_GOOGLE_KEY;
  private PARAMETERS = "&q=Italy,IT";
  private mapMarkers = [] as CMapMarkers[];
  private showNoMarkersError = false;
  private errors = {} as { [id: string]: string };
  private isErrorsEmpty = false;
  private markerKeys = new Set();
  private geolocateBtn: Omit<CBtn, "type" | "icon"> = {
    btnClass: "c-btn-outlined",
    label: "Geolocalizza obiettivo",
    labelClass: "c-label-dark",
    color: "var(--v-light-base)",
  };
  private coordinatesBtn: Omit<CBtn, "type"> = {
    btnClass: "c-btn-outlined",
    label: "Coordinate",
    labelClass: "c-label-dark",
    color: "var(--v-light-base)",
    icon: {
      name: "pin",
      fill: "var(--v-primary-lighten2)",
      height: 24,
      width: 24,
      iconStyle: "",
    },
  };
  private latLngForm = {
    show: false,
    request: {
      latitude: 0,
      longitude: 0,
    } as OfficeGeoRequest,
  };

  get height(): number {
    if (this.latLngForm.show) return 152;
    return 96;
  }

  get disabledSaveLatLngBtn(): boolean {
    return this.latLngForm.request.latitude <= 0 ||
      this.latLngForm.request.longitude <= 0
      ? true
      : false;
  }

  get jobFullAddress(): string {
    const city = this.validateString(this.job.city);
    const province = this.validateString(this.job.province)
      ? ` (${this.job.province.trim()})`
      : "";
    const address = this.validateString(this.job.address)
      ? `, ${this.job.address.trim()}`
      : "";
    return !city ? "" : ` - ${city}${province}${address}`;
  }

  get canSendGeoRequest(): boolean {
    return new Set(authModule.roles).has(Roles.SEND_GEO_REQUEST);
  }

  get canInsertCoordinates(): boolean {
    return new Set(authModule.roles).has(Roles.INSERT_COORDINATES);
  }

  private validateString(txt: string): string {
    let label = null as string;
    if (txt && txt.trim() !== "") {
      label = txt.trim();
    }
    return label;
  }

  private created(): void {
    this.setMapData();
  }

  private setMapData(): void {
    this.markerKeys.clear();
    this.mapMarkers.length = 0;
    if (this.mapData) {
      this.setCoordinates();
      this.getOfficeMarker();
      this.getStampsMarkers();
      this.isErrorsEmpty = Object.keys(this.errors).length === 0;
      if (this.mapMarkers.length) {
        this.ZOOM = 14;
        this.showNoMarkersError = false;
        if (this.mapMarkers.length === 1) {
          this.MAP_MODE = "place";
          this.PARAMETERS = `&q=${this.mapMarkers[0].coordinates.lat},${this.mapMarkers[0].coordinates.lng}`;
        } else {
          this.MAP_MODE = "directions";
          this.getDirectionsMapParams();
        }
      } else {
        this.showNoMarkersError = true;
      }
    }
  }

  private getOfficeMarker() {
    if (
      this.mapData.office.latitude &&
      this.mapData.office.longitude &&
      this.mapData.office.latitude != 0 &&
      this.mapData.office.longitude != 0
    ) {
      this.markerKeys.add(CMarkerTitles.WAYPOINTS);
      this.mapMarkers.push({
        title: CMarkerTitles.WAYPOINTS,
        coordinates: {
          lat: this.mapData.office.latitude,
          lng: this.mapData.office.longitude,
        } as CMarker,
      } as CMapMarkers);
    } else {
      this.$set(this.errors, "waypoints", "Obiettivo non geolocalizzato");
    }
  }

  private getStampsMarkers(): void {
    if (this.mapData.stamps.length) {
      for (const el of this.mapData.stamps) {
        const title =
          el.stampTypeId.trim() === "IN"
            ? CMarkerTitles.ORIGIN
            : CMarkerTitles.DESTINATION;
        if (
          el.latitude &&
          el.longitude &&
          el.latitude != 0 &&
          el.longitude != 0
        ) {
          this.markerKeys.add(title);
          const obj = {
            title: title,
            coordinates: {
              lat: el.latitude,
              lng: el.longitude,
            } as CMarker,
          } as CMapMarkers;
          title === CMarkerTitles.ORIGIN
            ? this.mapMarkers.unshift(obj)
            : this.mapMarkers.push(obj);
        } else {
          this.$set(
            this.errors,
            title,
            title === CMarkerTitles.ORIGIN
              ? "Timbratura d'inizio non geolocalizzata"
              : "Timbratura di fine non geolocalizzata"
          );
        }
      }
    }
  }

  private getDirectionsMapParams(): void {
    this.PARAMETERS = "&mode=walking";
    for (let idx = 0; idx < this.mapMarkers.length; idx++) {
      const m = this.mapMarkers[idx];
      if (idx === 0) {
        this.PARAMETERS += `&${CMarkerTitles.ORIGIN}=${m.coordinates.lat},${m.coordinates.lng}`;
      } else if (idx === this.mapMarkers.length - 1) {
        this.PARAMETERS += `&${CMarkerTitles.DESTINATION}=${m.coordinates.lat},${m.coordinates.lng}`;
      } else {
        this.PARAMETERS += `&${CMarkerTitles.WAYPOINTS}=${m.coordinates.lat},${m.coordinates.lng}`;
      }
    }
  }

  private async geolocateOffice(): Promise<void> {
    overlayModule.showOverlay();
    await notification.saveNotification({
      title: "Geolocalizza la posizione",
      message: `Conferma la tua posizione alla sede di ${this.job.officeName}${this.jobFullAddress}`,
      notificationType: NotificationTypes.GEOLOCATE,
      employeeId: this.job.employeeId,
      calendarJobId: this.job.calendarJobId,
    } as NotificationRequest);
    this.$emit("on-geolocate");
    snackbarModule.showSnackbar({
      message: `Richiesta di geolocalizzazione inviata con successo`,
      type: "success",
    });
    setTimeout(() => snackbarModule.hideSnackbar(), 5000);
  }

  private setCoordinates() {
    this.$set(
      this.latLngForm.request,
      "latitude",
      this.mapData.office.latitude
    );
    this.$set(
      this.latLngForm.request,
      "longitude",
      this.mapData.office.longitude
    );
  }
}
