export interface CMapMarkers {
  title: CMarkerTitles;
  coordinates: CMarker;
}

export interface CMarker {
  lat: number;
  lng: number;
}

export enum CMarkerTitles {
  ORIGIN = "origin",
  WAYPOINTS = "waypoints",
  DESTINATION = "destination",
}
