import { NotificationRequest } from "@/models/requests/notification-request.interface";
import { ApiService } from "../api.service";

const CONTROLLER = "notification";

class NotificationService extends ApiService {
  saveNotification(req: NotificationRequest) {
    return this.post<NotificationRequest, void>(CONTROLLER, req);
  }
}

export const notification = new NotificationService();
